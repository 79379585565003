import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={24}
    fill="none"
    {...props}
  >
    <g clipPath="url(#addengine)">
      <path
        fill="#5FC5FF"
        d="M14.333 10h-11v2h11v-2Zm0-4h-11v2h11V6Zm4 8v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4Zm-15 2h7v-2h-7v2Z"
      />
    </g>
    <defs>
      <clipPath id="addengine">
        <path fill="#fff" d="M.333 0h24v24h-24z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgComponent
